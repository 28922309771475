<template>
  <section id="media-preview" class="media-preview">
    <div
      class="media-preview__preview"
      :class="{ frame: frame }"
      v-show="mediaType"
    >
      <!-- <img id="preview-image" v-if="mediaType === 'image'" /> -->

      <img
        v-bind:src="output"
        id="preview-image"
        v-if="mediaType === 'image' && output.length > 0"
      />

      <Loader v-if="mediaType === 'image' && output.length === 0" />

      <div v-show="mediaType === 'video' && showPreviewVideo">
        <video id="preview-video" controls>
          Your browser does not support the video tag.
        </video>
      </div>

      <div v-if="mediaType === 'application'">
        <div id="preview-doc">
          <vs-icon icon="icon-paperclip" icon-pack="feather"></vs-icon>
          <span v-if="file">{{ file.name }}</span>
          <span v-if="savedFile">{{ savedFile.name }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FILE_FORMATS_MAP } from '@/constants/constants'

export default {
  name: 'MediaPreview',
  props: {
    file: {
      type: File,
      default: null
    },
    savedFile: {
      type: Object, // { name: "", fileData: "", fileUrl: "", "fileFormat": "" }
      default: null
    },
    frame: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showPreviewVideo: false,
      FILE_FORMATS_MAP,
      output: ''
    }
  },
  components: {
    Loader: () => import('./Loader.vue')
  },
  computed: {
    mediaType() {
      return (
        (this.file && this.file.type.split('/')[0]) ||
        (this.savedFile && this.FILE_FORMATS_MAP[this.savedFile.fileFormat])
      )
    }
  },
  methods: {
    previewImage(file) {
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          // const output = document.getElementById('preview-image')
          // output.src = reader.result
          this.output = reader.result
        }
        reader.readAsDataURL(this.file)
      } else {
        // const output = document.getElementById('preview-image')
        // output.src = this.savedFile.fileUrl
        this.output = this.savedFile.fileUrl
      }
    },
    previewVideo(file) {
      if (file) {
        const blobURL = URL.createObjectURL(this.file)
        document.querySelector('video').src = blobURL
      } else {
        document.querySelector('video').src = this.savedFile.fileUrl
      }
      this.showPreviewVideo = true
    },
    preview(file) {
      // if (this.file) {
      //   if (this.mediaType === 'image') {
      //     this.previewImage()
      //   } else if (this.mediaType === 'video') {
      //     this.previewVideo()
      //   }
      // } else {
      //   if (this.mediaType === 'video') {
      //     this.showPreviewVideo = true
      //   }
      // }

      if (this.mediaType === 'image') {
        this.previewImage(file)
      } else if (this.mediaType === 'video') {
        this.previewVideo(file)
      }
    }
  },
  watch: {
    file() {
      this.preview(this.file)
    },
    savedFile() {
      this.preview()
    }
  },
  mounted() {
    this.file ? this.preview(this.file) : this.preview()
  }
}
</script>

<style lang="scss">
.media-preview {
  &__preview {
    &.frame {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      padding: 10px;
      margin: 20px 0;
    }

    #preview-image,
    #preview-video {
      max-height: 190px;
      max-width: 100%;
    }

    #preview-doc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 120px;
      height: 160px;
      margin: 10px;
      padding: 20px;
      background: #f7f7f7;
      overflow: hidden;
      box-shadow: -5px 5px 5px -3px rgba(0, 0, 0, 0.1);
      word-break: break-all;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        border-width: 0 16px 16px 0;
        border-style: solid;
        border-color: #fff #fff #f7f7f7 #f7f7f7;
        background: #fffff0;
        -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
          -1px 1px 1px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
          -1px 1px 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3),
          -1px 1px 1px rgba(0, 0, 0, 0.2);
        /* Firefox 3.0 damage limitation */
        display: block;
        width: 0;
      }

      &.rounded {
        -moz-border-radius: 5px 0 5px 5px;
        border-radius: 5px 0 5px 5px;

        &:before {
          border-width: 8px;
          border-color: #fff #fff transparent transparent;
          -moz-border-radius: 0 0 0 5px;
          border-radius: 0 0 0 5px;
        }
      }

      .feather {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
